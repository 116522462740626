import Vue from 'vue'
import { store } from './store'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/projekte',
      name: 'projekte',
      component: () => import(/* webpackChunkName: "projekt" */ './views/Projekte')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/kunden',
      name: 'kunden',
      component: () => import(/* webpackChunkName: "kunden" */ './views/Kunden.vue')
    },
    {
      path: '/behinderungen',
      name: 'behinderungen',
      component: () => import(/* webpackChunkName: "behinderungen" */ './views/Behinderungen.vue')
    },
    {
      path: '/lieferanten',
      name: 'lieferanten',
      component: () => import(/* webpackChunkName: "lieferanten" */ './views/Lieferanten.vue')
    },
    {
      path: '/tasks',
      name: 'aufgaben',
      component: () => import(/* webpackChunkName: "lieferanten" */ './views/Aufgaben.vue')
    },

    {
      path: '*',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/auswertung',
      name: 'auswertung',
      component: () => import(/* webpackChunkName: "lieferanten" */ './views/Auswertung.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "lieferanten" */ './views/Dashboard.vue')
    },
    {
      path: '/import',
      name: 'import',
      component: () => import(/* webpackChunkName: "QR" */ './views/Import.vue')
    },
    {
      path: '/profil',
      name: 'profil',
      component: () => import(/* webpackChunkName: "profil" */ './views/Profil.vue')
    },
    {
      path: '/benutzer',
      name: 'benutzer',
      component: () => import(/* webpackChunkName: "benutzer" */ './views/Benutzer.vue')
    },
    {
      path: '/version',
      name: 'version',
      component: () => import(/* webpackChunkName: "benutzer" */ './views/Version.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const session = store.getters.token
  let loggedIn = false
  if (session) {
    loggedIn = true
  }
  if (authRequired && !loggedIn) {
    localStorage.removeItem('session')
    router.push({ name: 'login' })
    return
  }
  next()
})
