<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="mb-3" :sticky="true">
    <b-navbar-brand to="/aufgaben">
      <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="70" height="42" viewBox="0 0 117 72">
        <g class="logo__name">
          <path
            d="M18.64,36.87H7.92c-5.11,0-7.92,2.49-7.92,7,0,3,1.36,6.57,7.81,6.57h5c1.74,0,1.88,.67,1.88,1.42,0,.45,0,1.29-2.18,1.29H.76v5.34H12.76c6.55,0,7.92-3.69,7.92-6.79,0-2.3-.65-3.99-1.93-5.02-1.2-1-3.04-1.49-5.62-1.49H7.52c-1.42,0-1.53-.52-1.53-1.2,0-1.09,.36-1.77,2.12-1.77h11.65v-5.34h-1.12Z"
            style="fill: #fff;"></path>
          <path
            d="M38.11,36.87h-5.88c-4.64,0-10.16,1.94-10.16,11.16,0,3.95,1.09,6.73,3.34,8.52,.96,.8,2.09,1.36,3.3,1.64,1.12,.24,2.27,.34,3.42,.3h7.13v-5.39h-7.24c-1.48,.02-2.46-.27-2.93-.87-.4-.49-.87-1.58-.87-4.06,0-2.31,.31-3.95,.92-4.86,.33-.43,.76-.77,1.26-.99,.5-.22,1.05-.3,1.59-.24h7.24v-5.2h-1.12"
            style="fill: #fff;"></path>
          <path d="M57.8,36.87h-4.68v8.05h-6.19v-8.05h-5.83v21.62h5.83v-8.26h6.19v8.26h5.8v-21.62h-1.12Z"
            style="fill: #fff;"></path>
          <path
            d="M80.32,36.87h-4.63v12.94c.11,1.06-.15,2.13-.75,3.02-.86,.64-1.93,.92-2.99,.8-1.5,0-2.54-.27-3-.79-.35-.37-.74-1.17-.74-3.04v-12.94h-5.72v12.83c-.03,1.12,.04,2.24,.21,3.35,.13,.95,.52,1.85,1.12,2.6,1.63,1.96,4.29,2.94,8.12,2.97,3.81-.03,6.46-1.01,8.1-3,.93-1.06,1.39-3.01,1.39-5.92v-12.83h-1.11Z"
            style="fill: #fff;"></path>
          <path
            d="M89.4,36.87h-4.6v12.65c-.04,1.21,.07,2.41,.34,3.59,.28,1.14,.86,2.18,1.68,3.02,.86,1.01,2.01,1.71,3.3,2.01,1.24,.28,2.52,.39,3.79,.35h2.9v-5.23h-2.81c-1.53,0-2.55-.29-3.04-.87l-.05-.06-.05-.05c-.17-.23-.26-.49-.27-.77l-.07-1.97v-12.67h-1.12Zm-.05,17.06s-.02-.03-.03-.04c-.01-.01-.05-.04-.07-.06l.1,.1"
            style="fill: #fff;"></path>
          <path d="M115.56,36.87h-17.4v5.42h10.43l-10.46,11.38v4.82h18.55v-5.55h-10.3l10.3-11.39v-4.68h-1.12"
            style="fill: #fff;"></path>
        </g>
        <g class="logo__image">
          <g class="logo__subline">
            <path
              d="M17.96,65.2l-.38,.8s-.02,.04-.04,.05c-.02,.01-.04,.02-.06,.03-.03,0-.05-.01-.08-.02l-.34-.18c-.32-.17-.68-.26-1.04-.27-.61,0-.97,.29-.97,.77,0,.39,.17,.58,.95,.96l.58,.28c.45,.16,.84,.45,1.11,.84,.27,.39,.42,.85,.42,1.33,.01,.3-.04,.6-.16,.88-.11,.28-.29,.53-.51,.74-.22,.21-.48,.37-.77,.46-.29,.1-.59,.13-.89,.11-.71-.02-1.42-.18-2.06-.49-.03,0-.06-.03-.08-.05-.02-.03-.03-.06-.04-.09,0-.04,.02-.07,.03-.11l.34-.91c.02-.06,.06-.09,.1-.09,.04,0,.07,.01,.1,.03,.49,.3,1.05,.47,1.62,.5,.12,.02,.25,0,.36-.03,.12-.04,.23-.1,.32-.18,.09-.08,.16-.19,.21-.3,.05-.11,.07-.24,.06-.36,0-.59-.15-.73-1.42-1.37-.43-.18-.83-.44-1.16-.76-.17-.17-.3-.37-.38-.6-.08-.22-.12-.46-.11-.7,0-.29,.06-.58,.18-.85,.12-.27,.29-.5,.51-.7,.22-.19,.48-.34,.76-.42,.28-.08,.57-.11,.86-.07,.67,0,1.33,.18,1.91,.51,.08,.05,.1,.09,.1,.15,0,.03,0,.06-.02,.08"
              style="fill: #fff;"></path>
            <path
              d="M21.66,64.5c.09,0,.1,.02,.16,.15l.85,1.8c.13,.27,.24,.55,.33,.84l.11,.31c.11-.43,.27-.84,.47-1.24l.81-1.72c.06-.13,.09-.15,.15-.15h.06s.05,0,.1,0h1.02c.13,.04,.19,.08,.19,.15,0,.05-.03,.1-.06,.14l-2.08,4.07v2.75c0,.19-.02,.22-.22,.22h-1.01c-.2,0-.22-.02-.22-.22v-2.76l-2.12-4.08s-.03-.06-.04-.06c0-.02-.01-.05-.01-.08,0-.08,.05-.11,.19-.13"
              style="fill: #fff;"></path>
            <path
              d="M32.2,65.06l-.38,.8s-.02,.04-.04,.05c-.02,.01-.04,.02-.06,.02-.03,0-.05-.01-.08-.02l-.34-.18c-.32-.17-.68-.26-1.04-.27-.61,0-.97,.29-.97,.77,0,.39,.17,.58,.94,.96l.58,.28c.45,.16,.84,.45,1.11,.83s.42,.85,.42,1.33c.01,.3-.04,.6-.16,.89-.11,.28-.29,.53-.51,.74-.22,.21-.48,.37-.77,.47-.29,.1-.59,.14-.89,.11-.72-.02-1.42-.18-2.07-.49-.03,0-.06-.03-.08-.05-.02-.03-.03-.06-.04-.09,0-.04,.02-.07,.03-.11l.34-.91c.02-.05,.06-.09,.1-.09,.04,0,.07,.01,.1,.03,.49,.3,1.05,.47,1.62,.5,.12,.02,.25,0,.36-.03,.12-.04,.23-.1,.32-.18,.09-.08,.16-.19,.21-.3,.05-.11,.07-.24,.06-.36,0-.59-.15-.73-1.42-1.37-.43-.18-.83-.44-1.16-.76-.17-.17-.29-.37-.38-.6-.08-.22-.12-.46-.11-.7,0-.29,.06-.58,.18-.85,.12-.27,.29-.51,.51-.7,.22-.19,.48-.34,.76-.42,.28-.08,.58-.11,.86-.07,.67,0,1.33,.18,1.91,.51,.08,.06,.1,.09,.1,.15t-.02,.08"
              style="fill: #fff;"></path>
            <path
              d="M39.43,65.68c-.03,.15-.07,.17-.23,.17h-1.54v5.77c0,.19-.02,.22-.22,.22h-1.03c-.2,0-.22-.02-.22-.22v-5.77h-1.33c-.2,0-.23-.02-.23-.22v-.83c0-.18,.03-.22,.23-.22h4.36c.16,0,.22,.03,.22,.12"
              style="fill: #fff;"></path>
            <path
              d="M43.68,65.75v1.74h1.76c.18,0,.22,.02,.22,.22v.72c0,.19-.03,.22-.22,.22h-1.76v2.05h2.21c.19,0,.22,.03,.22,.22v.72c0,.19-.02,.22-.22,.22h-3.45c-.2,0-.22-.02-.22-.22v-6.82c0-.18,.02-.22,.22-.22h3.53c.16,0,.21,.03,.21,.12,0,.04,0,.09-.02,.13l-.21,.72c-.03,.14-.09,.17-.23,.17h-2.04Z"
              style="fill: #fff;"></path>
            <path
              d="M52.13,68.48l.09-.32c.1-.37,.18-.64,.27-.91l.89-2.65c.06-.16,.06-.17,.24-.17h1c.18,0,.21,.03,.22,.22l.34,6.8v.08c0,.12-.05,.15-.21,.15h-.96c-.19,0-.22-.03-.23-.22l-.06-3.73c-.01-.12-.01-.24-.01-.37,0-.1,0-.23,.01-.38v-.77c-.16,.75-.27,1.16-.38,1.53l-.68,2.16c-.06,.16-.06,.17-.24,.17h-.64c-.17,0-.19-.01-.24-.18l-.59-2.05c-.08-.26-.24-.92-.39-1.63v1.66l-.08,3.59c0,.19-.03,.22-.22,.22h-.86c-.16,0-.21-.03-.21-.15v-.07l.3-6.81c0-.19,.03-.22,.22-.22h1.04c.16,0,.18,.02,.24,.17l.85,2.66c.06,.2,.16,.66,.28,1.22"
              style="fill: #fff;"></path>
            <path
              d="M62.53,65.68c-.03,.15-.07,.17-.23,.17h-1.53v5.77c0,.19-.02,.22-.22,.22h-1.03c-.2,0-.22-.02-.22-.22v-5.77h-1.33c-.19,0-.23-.02-.23-.22v-.82c0-.18,.03-.22,.23-.22h4.36c.17,0,.22,.03,.22,.12"
              style="fill: #fff;"></path>
            <path
              d="M69.25,65.57c-.03,.14-.09,.17-.23,.17h-2.26v1.74h1.76c.18,0,.22,.02,.22,.22v.72c0,.19-.03,.22-.22,.22h-1.76v2.05h2.42c.2,0,.22,.03,.22,.22v.72c0,.19-.02,.22-.22,.22h-3.65c-.2,0-.22-.02-.22-.22v-6.82c0-.18,.02-.22,.22-.22h3.53c.16,0,.21,.03,.21,.12"
              style="fill: #fff;"></path>
            <path
              d="M76.58,65.15l-.43,.78c-.05,.06-.07,.08-.11,.08-.04,0-.07-.01-.1-.03-.08-.05-.15-.1-.22-.16-.24-.2-.54-.31-.86-.33-.81,0-1.22,.75-1.22,2.27,0,1.98,.44,2.89,1.37,2.89,.35,0,.69-.14,.94-.38,.03-.02,.06-.03,.1-.04,.04,0,.08,.02,.11,.09l.43,.74s.02,.07,.02,.11c0,.1-.08,.19-.29,.32-.44,.28-.95,.42-1.47,.4-1.77,0-2.73-1.36-2.73-3.89-.09-1.03,.21-2.07,.84-2.89,.23-.26,.51-.47,.82-.62,.32-.14,.66-.21,1-.21,.61,0,1.21,.22,1.68,.6,.08,.08,.13,.14,.13,.2,0,.03-.01,.06-.02,.09"
              style="fill: #fff;"></path>
            <path
              d="M80.31,67.56h1.97v-2.74c0-.18,.02-.22,.22-.22h1.01c.2,0,.22,.03,.22,.22v6.82c0,.19-.02,.22-.22,.22h-1.01c-.2,0-.22-.02-.22-.22v-2.99h-1.97v2.99c0,.19-.03,.22-.22,.22h-1.02c-.2,0-.22-.02-.22-.22v-6.82c0-.18,.02-.22,.22-.22h1.02c.19,0,.22,.03,.22,.22v2.74Z"
              style="fill: #fff;"></path>
            <path
              d="M90.73,69.51v-4.69c-.01-.18,0-.22,.21-.22h.79c.2,0,.22,.02,.22,.22v6.82c0,.19-.02,.22-.22,.22h-.68c-.19,0-.21-.02-.31-.21l-1.82-3.42c-.28-.47-.5-.97-.67-1.49v.36c.02,.31,.03,.44,.03,.73v3.81c0,.19-.03,.22-.22,.22h-.81c-.19,0-.21-.02-.21-.22v-6.82c0-.18,.02-.22,.21-.22h.84c.18,0,.21,.01,.29,.18l1.67,3.11c.27,.52,.49,1.06,.67,1.62"
              style="fill: #fff;"></path>
            <path
              d="M96.18,64.59h-1.02c-.2,0-.22,.03-.22,.22v6.82c0,.19,.03,.22,.22,.22h.99c.2,0,.22-.02,.22-.22l.02-6.82c0-.18-.02-.22-.22-.22Z"
              style="fill: #fff;"></path>
            <path
              d="M100.71,67.79l2.21-3.07c.07-.09,.11-.12,.16-.12h1.24c.11,.06,.14,.09,.14,.14,0,.06-.03,.11-.08,.15l-2.43,3.24,2.7,3.39s.07,.09,.07,.15c0,.07,0,.09-.14,.15,0,0-1.39,0-1.4,0h0s-.08-.03-.14-.12l-2.33-3.06v2.97c0,.19-.03,.22-.22,.22h-1.02c-.2,0-.22-.02-.22-.22v-6.82c0-.18,.02-.22,.22-.22h1.02c.18,0,.22,.03,.22,.22v2.98"
              style="fill: #fff;"></path>
          </g>
          <path d="M99.86,26.73H17.56c-.63,1.51-1.17,3.05-1.63,4.62H101.49c-.46-1.57-1-3.11-1.63-4.62"
            style="fill: #fff;"></path>
          <path
            d="M96.79,20.71c-.4-.67-.83-1.32-1.27-1.96H21.9c-.44,.64-.86,1.3-1.27,1.96-.31,.5-.6,1.01-.89,1.53H97.68c-.29-.52-.58-1.03-.89-1.53Z"
            style="fill: #fff;"></path>
          <path d="M90.29,12.43H27.12c-.7,.7-1.38,1.43-2.03,2.18H92.32c-.65-.75-1.33-1.47-2.03-2.18"
            style="fill: #fff;"></path>
          <path d="M84.38,7.44H33.04c-.66,.47-1.31,.95-1.94,1.45h55.22c-.64-.5-1.28-.98-1.94-1.45Z" style="fill: #fff;">
          </path>
          <path d="M77.21,3.32H40.21c-.64,.29-1.27,.6-1.89,.92h40.78c-.62-.32-1.25-.63-1.89-.92" style="fill: #fff;">
          </path>
          <path d="M66.59,0h-15.76c-1.02,.18-2.02,.4-3.01,.65h21.78c-.99-.25-2-.47-3.01-.65Z" style="fill: #fff;">
          </path>
        </g>
      </svg>
    </b-navbar-brand>
    <span class="navbar-text mr-2">
      <strong>ProKom</strong>
    </span>
    <b-navbar-toggle target="nav-collapse" is-nav></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav v-if="checkPermisison('login')">
      <b-navbar-nav>
        <b-nav-item v-if="checkPermisison('login')">
          <router-link class="nav-link" :to="{ name: 'aufgaben' }">Aufgaben</router-link>
        </b-nav-item>
        <b-nav-item v-if="checkPermisison('pro_edit')">
          <router-link class="nav-link" :to="{ name: 'dashboard' }">Dashboard</router-link>
        </b-nav-item>
        <b-nav-item v-if="checkPermisison('import')">
          <router-link class="nav-link" :to="{ name: 'import' }">Import</router-link>
        </b-nav-item>
        <b-nav-item v-if="checkPermisison('pro_edit')">
          <router-link class="nav-link" :to="{ name: 'projekte' }">Projekte</router-link>
        </b-nav-item>
        <b-nav-item-dropdown text="Einstellungen" class="custom-dropdown mt-2"
          v-if="checkPermisison('client_edit') || checkPermisison('supplier_edit') || checkPermisison('disturb_edit')"
          bottom>
          <b-dropdown-item v-if="checkPermisison('client_edit')"><router-link class="nav-link"
              :to="{ name: 'kunden' }">Kunden</router-link></b-dropdown-item>
          <b-dropdown-item v-if="checkPermisison('supplier_edit')"><router-link class="nav-link"
              :to="{ name: 'lieferanten' }">Lieferanten</router-link></b-dropdown-item>
          <b-dropdown-item v-if="checkPermisison('disturb_edit')"><router-link class="nav-link"
              :to="{ name: 'behinderungen' }">Behinderungen</router-link></b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="checkPermisison('pro_edit')">
          <router-link class="nav-link" :to="{ name: 'auswertung' }">Auswertungen</router-link>
        </b-nav-item>
        <b-nav-item v-if="checkPermisison('user')">
          <router-link class="nav-link" :to="{ name: 'benutzer' }">Benutzer</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown class="custom-dropdown mt-2" right>
          <!-- Using 'button-content' slot -->
          <template slot="button-content">
            <font-awesome-icon :icon="['fas', 'user']" />
          </template>
          <b-dropdown-item @click="openProfil"><span><font-awesome-icon :icon="['fas', 'wrench']" />
              Profil</span></b-dropdown-item>
          <b-dropdown-item @click="logout"> <span><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> {{
    $store.getters.user }}</span></b-dropdown-item>
          <b-dropdown-item @click="openChanges"><span><font-awesome-icon :icon="['fas', 'history']" />
              {{ version }}</span></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
// eslint-disable-next-line
import { checkPermisison } from '../utils/permission'
import { versions } from '../utils/version'
export default {
  data() {
    return {
      login: this.$store.getters.user,
      checkPermisison: checkPermisison,
      version: ''
    }
  },
  mounted() {
    this.version = versions.currentVersion
  },
  methods: {
    logout() {
      this.$cookies.set('Token', null)
      this.$cookies.set('Username', null)
      this.$router.push('login')
    },
    openChanges() {
      this.$router.push('version')
    },
    openProfil() {
      this.$router.push('profil')
    }
  }
}
</script>

<style>
.custom-dropdown .dropdown-menu {
  background-color: #dc3545;
  border: none;
}
</style>
