import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    token: null,
    acl: null,
    user: null,
    userId: null,
    selectedProject: null,
    selectedName: null,
    selectedStatus: null,
    projects: [],
    scan: null,
    branch: ''
  },
  mutations: {
    setBranch(state, branch) {
      state.branch = branch
    },
    setScan(state, scan) {
      state.scan = scan
    },
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    setAcl(state, acl) {
      state.acl = acl
    },
    setUserId(state, ui) {
      state.userId = ui
    },
    setProject(state, pro) {
      state.selectedProject = pro
    },
    setName(state, name) {
      state.selectedName = name
    },
    setStatus(state, status) {
      state.selectedStatus = status
    },
    setProjects(state, projects) {
      state.projects = projects
    }
  },
  getters: {
    token: (state) => { return state.token },
    user: (state) => { return state.user },
    userId: (state) => { return state.userId },
    acl: (state) => { return state.acl },
    project: (state) => { return state.selectedProject },
    name: (state) => { return state.selectedName },
    status: (state) => { return state.selectedStatus },
    projects: (state) => { return state.projects },
    scan: (state) => { return state.scan },
    branch: (state) => { return state.branch }
  }
})
