<template>
  <div>
    <Navigation />
    <b-container fluid>

    <main>
      <router-view></router-view>
    </main>
    </b-container>
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  text-align: center;
}
.container-fluid {
  max-width: 90% !important;
}
</style>

<script>
import Navigation from './components/Navigation'

export default {
  components: {
    Navigation
  }
}
</script>
