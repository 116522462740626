// noinspection ES6UnusedImports
import Vue from 'vue'
import { router } from './router'
import { store } from './store'
import App from './App'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueProgressBar from 'vue-progressbar'
import './assets/css/ststyle.scss'
import VeeValidate from 'vee-validate'
import moment from 'moment-timezone'
import VueNumerals from 'vue-numerals'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('2d')

Vue.use(VueNumerals)

// with options
Vue.use(VueNumerals, {
  locale: 'de'
})

// Zeit
moment.locale('de')
moment.tz.setDefault('Europe/Berlin')

Vue.prototype.$moment = moment

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
})

const options = {
  color: '#e3004a',
  thickness: '3px',
  transition: {
    speed: '0.1s'
  }
}

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(VueProgressBar, options)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
